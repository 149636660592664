import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./user/user.slice";
import { notificationReducer } from "./notification/notification.slice";
import { sessionVideoReducer } from "./session-video/session-video";
import { paymentReducer } from "./payment/payment.slice";
import { videoPlayerReducer } from "./video-player/videoplayer.slice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    notification: notificationReducer,
    sessionVideo: sessionVideoReducer,
    payment: paymentReducer,
    videoPlayer: videoPlayerReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
