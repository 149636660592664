import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Peer } from "peerjs";
import { RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import p2pService from "../../services/peerToPeer";
import { peertopeer, Video_Metadata } from "../../services/spica/bucket/bucket";
import styles from "./PeerToPeer.module.scss";
import videojs from "video.js";
import PlayerService from "../../services/player.service";


const PeerToPeer = () => {
  const [peer, setPeer] = useState<Peer | null>(null);
  const [connection, setConnection] = useState<any>(null); //maybe use DataConnection of peer.js
  const [doctorPeerId, setDoctorPeerId] = useState<string>("");
  const [patientPeerId, setPatientPeerId] = useState<string>("");

  const user = useSelector((state: RootState) => state.user.user);
  const isPatient = Object.keys(user).length === 0;

  const [patientMessage, setPatientMessage] = useState<string>("");


  useEffect(() => {
    const newPeer = new Peer();

    newPeer.on("open", function (id) {
      console.log("here again");

      if (isPatient) {
        setPatientPeerId(id);
        p2pService.updatePeerConnection("6749bb6b51ad0b002d01583d", {
          answer_candidates: id,
        });
        console.log("Patient ID:", id);
      } else {
        setDoctorPeerId(id);
        p2pService.updatePeerConnection("6749bb6b51ad0b002d01583d", {
          offer_candidates: id,
        });
        console.log("Doctor ID:", id);
      }
    });

    newPeer.on("connection", function (conn) {
      setConnection(conn);
      console.log("connection", conn);
      conn.on("open", () => {
        conn.on("data", (data: any) => {
          console.log("Received data:", data);
        });
      });
    });

    setPeer(newPeer);

    return () => {
      newPeer.destroy();
    };
  }, [isPatient]);


  const startConnect = async () => {
    const patientPeerId = await p2pService
      .getP2pConnection("6749bb6b51ad0b002d01583d")
      .then((res) => res.answer_candidates);

    const offer = peer?.connect(patientPeerId!);
    console.log("Connection offer:", offer);

    // Set the connection and add event listeners
    if (offer) {
      setConnection(offer);

      offer.on("open", () => {
        console.log("Connection opened");
        offer.on("data", (data: any) => {
          console.log("Received data:", data);
        });
      });
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPatientMessage(e.target.value);
  };

  const sendData = useCallback((data: any) => {
    if (connection && connection.open) {
      console.log("Sending data:", data);
      connection.send(data);
    } else {
      console.error("Connection is not open.");
    }
  }, [connection, patientMessage]);


  return (
    <div>
      <h1>Peer-to-Peer Connection</h1>
      {isPatient ? (
        <p>Patient ID: {patientPeerId}</p>
      ) : (
        <p>Doctor ID: {doctorPeerId}</p>
      )}

      {!isPatient && <button onClick={startConnect}>Create Connection</button>}


        <div>
          <input type="text" onChange={handleChange} />
          <button onClick={()=> {sendData({isPlaying: true})}}>Send Data</button>
        </div>


    </div>
  );
};

export default PeerToPeer;
