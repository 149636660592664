import React, { FC, ReactNode } from "react";
import MuiButton from "@mui/material/Button";
import { SxProps, Theme } from "@mui/material";
import { globalColors } from "../../style/colors";

export interface iButtonProps {
  type?: "primary" | "outlined" | "secondary" | "tertiary";
  disabled?: boolean;
  color?: string;
  bgColor?: string;
  bgHover?: string;
  borderRadius?: string;
  borderColor?: string;
  borderStyle?: string;
  borderWidth?: string;
  fontSize?: string;
  padding?: string;
  margin?: string;
  horizontalSize?: number | string;
  textTransform?: string;
  children?: ReactNode;
  className?: string;
  onClick?: (event?: any) => void;
}

const WButton: FC<iButtonProps> = ({
  type = "primary",
  disabled,
  children,
  color,
  borderColor = "#343333",
  borderWidth = "1px",
  borderStyle = "solid",
  padding = "5px 10px",
  margin = "0",
  bgColor,
  bgHover = "rgb(238 61 0)",
  borderRadius = "0px",
  fontSize = "16px",
  textTransform = "capitalize",
  horizontalSize,
  className,
  onClick,
}) => {
  const globalStyles: SxProps<Theme> = {
    color: color || "white",
    padding,
    margin,
    width: `${horizontalSize}%`,
    "&:hover": {
      background: bgHover,
      color: "white",
    },
    textTransform,
    fontSize,
    borderRadius,
    cursor: "pointer",
  };

  const specificStyles: { [key: string]: SxProps<Theme> } = {
    primary: {
      ...globalStyles,
      background: globalColors.primary,
    },
    outlined: {
      ...globalStyles,
      borderWidth,
      borderColor,
      borderStyle,
      color: color || "black",
      background: bgColor,
    },
    secondary: {
      ...globalStyles,
      background: globalColors.secondary,
    },
    tertiary: {
      ...globalStyles,
      background: bgColor,
    },
  };

  return (
    <MuiButton
      disabled={disabled}
      onClick={onClick}
      sx={specificStyles[type]}
      className={className}
    >
      {children}
    </MuiButton>
  );
};

export default WButton;
