import { RealtimeConnectionOne, initialize } from "@spica-devkit/bucket";
import {
  configurations,
  Configurations,
  PeerToPeer,
  peertopeer,
  video_metadata,
  Video_Metadata,
} from "./spica/bucket/bucket";

class IP2PService {
  constructor() {
    this._initialize();
  }

  private _call: PeerToPeer | null = null;
  private _configs: Configurations[] | null = null;
  private _realtimeConnection: RealtimeConnectionOne<PeerToPeer> | null = null;
  private _realtimeConnectionSubscription: any | null = null;
  private _callVideos: Video_Metadata[] | null = null;
  private _isPatientRegistrationIdUpdated = false;
  private _isSessionEndSoonPopupShowed = false;

  private _initialize() {
    return initialize({
      apikey: "4vrax19lq2dzg57",
      publicUrl: "https://video-portal-75dd5.hq.spicaengine.com/api",
    });
  }

  public get call() {
    return this._call;
  }

  public set call(data: PeerToPeer | null) {
    this._call = data;
  }

  public get configs() {
    return this._configs;
  }

  public set configs(data: Configurations[] | null) {
    this._configs = data;
  }

  public get isSessionEndSoonPopupShowed() {
    return this._isSessionEndSoonPopupShowed;
  }

  public set isSessionEndSoonPopupShowed(data: boolean) {
    this._isSessionEndSoonPopupShowed = data;
  }

  public get isPatientRegistrationIdUpdated() {
    return this._isPatientRegistrationIdUpdated;
  }

  public set isPatientRegistrationIdUpdated(data: boolean) {
    this._isPatientRegistrationIdUpdated = data;
  }

  public get realtimeConnectionSubscription(): any | null {
    return this._realtimeConnectionSubscription;
  }

  public set realtimeConnectionSubscription(data: any | null) {
    this._realtimeConnectionSubscription = data;
  }

  public get sessionVideos(): Video_Metadata[] | null {
    return this._callVideos;
  }

  public set sessionVideos(data: Video_Metadata[] | null) {
    this._callVideos = data;
  }

  public set realtimeConnection(data: RealtimeConnectionOne<PeerToPeer>) {
    this._realtimeConnection = data;
  }

  public patch(patchFields: PeerToPeer) {
    return this._realtimeConnection?.patch({ ...patchFields });
  }

  public connectToSessionRealtime(sessionId: string) {
    return peertopeer.realtime.get(sessionId);
  }

  public getSessionVideos(videoIds: string[]) {
    return video_metadata.getAll({
      queryParams: {
        filter: {
          _id: {
            $in: videoIds,
          },
        },
      },
    });
  }

  public async fetchConfigs() {
    this._initialize();
    const res = await configurations.getAll();
    return (this.configs = res);
  }
  public getSessionsInformations(userId: string, data:any = {}) {
    return peertopeer.filter({ user: userId, ...data })
      .getAll();
  }
  public updatePeerConnection(peerID: string, patchFields: {}) {
    return peertopeer.patch({ _id: peerID, ...patchFields });
  }
  createSession(data: PeerToPeer) {
    return peertopeer.insert(data);
  }


  public getP2pConnection(id: string){
    return peertopeer.get(id);
  }
}

const p2pService = new IP2PService();
export default p2pService;
