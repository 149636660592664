export interface PeerInformations{
    currentId: string,
    previousId: string,
  }

interface Request{
    doctorInformations: PeerInformations,
    patientInformations: PeerInformations,
    doctorBufferAmount?: number
    patientBufferAmount?: number,
}

interface HandlerResult {
    success: boolean;
    stoppedBy?: string; 
  }


export abstract class Handler {
   private nextHandler: Handler | null = null;

	constructor() {
	}

	setNext(handler: Handler): Handler {
		if (!(handler instanceof Handler)) {
			throw new Error("Next handler must be an instance of Handler");
		}
		this.nextHandler = handler;
        return handler;
	}

	handle(request: Request): HandlerResult {
		if (this.nextHandler) {
			return this.nextHandler.handle(request);
		}
		return {success: true};
	}
}

export class DoctorPeerAvailabilityHandler extends Handler{
    handle(request: Request): HandlerResult {
        const {currentId, previousId} = request.doctorInformations;
        if(currentId != previousId) return {success: false, stoppedBy: "DoctorPeerAvailabilityHandler"};
        return super.handle(request);
    }
}
export class PatientPeerAvailabilityHandler extends Handler{
    handle(request: Request): HandlerResult {
        const {currentId, previousId} = request.patientInformations;
        if(currentId != previousId) return {success: false, stoppedBy: "PatientPeerAvailabilityHandler"};
        return super.handle(request);
    }
}

export class BufferAmountHandler extends Handler {
    handle(request: Request): HandlerResult {
      const { doctorBufferAmount = 0, patientBufferAmount = 0 } = request;
      if (doctorBufferAmount >= 5 && patientBufferAmount >= 5) {
        return { success: false, stoppedBy: "BufferAmountHandler" };
      }
      return super.handle(request);
    }
  }
  