import React from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import PatientScreen from '../patient-screen/PatientScreen';
import DoctorScreen from '../doctor-screen/DoctorScreen';

const Session = () => {
    const user = useSelector((state: RootState) => state.user.user);

    const isPatient = Object.keys(user).length === 0;

  return (
    <div>
        {isPatient ? <PatientScreen/>: <DoctorScreen/>}
    </div>
  )
}

export default Session