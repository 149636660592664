import Peer, { DataConnection } from "peerjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import sessionService from "../../services/session.service";
import { useNavigate, useParams } from "react-router-dom";
import PatientScreen from "../patient-screen/PatientScreen";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import WGlobalDialog, {
  IWGlobalDialog,
} from "../../prefabs/global-dialog/global-dialog";

import {
  DoctorPeerAvailabilityHandler,
  PatientPeerAvailabilityHandler,
  PeerInformations,
} from "../../services/sessionFlowChain";

import styles from "./DoctorScreen.module.scss";
import { Sessions, Video_Metadata } from "../../services/spica/bucket/bucket";

import videojs from "video.js";
import "videojs-vr";
import "../../../node_modules/video.js/dist/video-js.css";
import "../../../node_modules/videojs-vr/dist/videojs-vr.css";
import PlayerService from "../../services/player.service";
import TweenService from "../../services/tween.service";
import SessionTopBar from "../../prefabs/session/top-bar/top-bar";
import { iSessionTopBarRightItem } from "../../interfaces/session-top-bar-right-item";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import userSerivce from "../../services/user.service";
import { WMenu } from "../menu/menu";
import { WMenuItem } from "../wrapper/wrapper";
import { WDialog } from "../dialog/dialog";
import Library from "../../prefabs/library/library";

const DoctorScreen = () => {
  const videoData = {
    _id: "6750cad451ad0b002d01cc0e",
    title: "Asansör Fobisi",
    description:
      "Yüksek katlı bir binanın asansör kapısında bekleyerek başlarsınız. Asansöre biner ve ara katlarda durmadan 19. katta inersiniz.",
    subtitle: "Asansör (1-19)",
    duration_seconds: 232,
    content:
      "https://storage.googleapis.com/download/storage/v1/b/hq-video-portal-75dd5/o/667d5ae86d8cd3002c55943a?alt=media",
    thumbnail:
      "https://storage.googleapis.com/download/storage/v1/b/hq-video-portal-75dd5/o/669633ece49d40002c307f1e?alt=media",
    categories: ["66963434e49d40002c307f55"],
    video_demo:
      "https://storage.googleapis.com/download/storage/v1/b/hq-video-portal-75dd5/o/663df1849cc20b002d2725a4?alt=media",
  };

  const { sessionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const sessionUrl = window.location.href;
  const user = useSelector((state: RootState) => state.user.user);
  const [session, setSession] = useState<Sessions>();

  const [peer, setPeer] = useState<Peer | null>(null);
  const [connection, setConnection] = useState<DataConnection | null>(null);
  const [doctorBufferedAmount, setDoctorBufferedAmount] = useState<number>(0);
  const [patientBufferedAmount, setPatientBufferedAmount] = useState<number>(0);
  const [receiverPeerId, setReceiverPeerId] = useState<string | null>(null);
  const [offererPeerId, setOffererPeerId] = useState<string | null>(null);

  const [disconnectedPeer, setDisconnectedPeer] = useState<string>("doctor");

  const [videoPlayer, setVideoPlayer] = useState<any>(null);

  const [patientVideoStatus, setPatientVideoStatus] = useState<string>("none");

  const videoRef = useRef<HTMLVideoElement>(null);

  const [isConnectionAvailable, setIsConnectionAvailable] =
    useState<boolean>(true);

  const [isSessionStartModalOpen, setIsSessionStartModalOpen] =
    useState<boolean>(false);

  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(false);

  const [title, setTitle] = useState<string | null>(null);

  const [clipboardTooltip, setClipboardTooltip] = useState(
    "Seans bağlantı linkini kopyala"
  );

  const [isVideoLibraryVisible, setIsVideoLibraryVisible] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isFinishSessionDialogVisible, setIsFinishSessionDialogVisible] =
    useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [isSessionFinishedDialogVisible, setIsSessionFinishedDialogVisible] =
    useState(false);

  const showFinishSessionDialog = () => setIsFinishSessionDialogVisible(true);
  const hideFinishSessionDialog = () => setIsFinishSessionDialogVisible(false);

  const [isSessionEndSoonPopupVisible, setIsSessionEndSoonPopupVisible] =
    useState(false);

    const [
      isSessionCountFinishedPopupVisible,
      setIsSessionCountFinishedPopupVisible,
    ] = useState(false);

  const topBarRightItems: Array<iSessionTopBarRightItem> = [
    {
      icon: <FileCopyIcon />,
      onClick: () => copySessionUrlToClipboard(),
      tooltip: clipboardTooltip,
    },
    {
      icon: <MoreHorizIcon />,
      onClick: (event) => handleMenuClick(event),
    },
    {
      icon: <CancelRoundedIcon />,
      onClick: () => showFinishSessionDialog(),
      tooltip: "Seansı bitir",
    },
  ];

  useEffect(() => {

    const newPeer = new Peer();

    let doctorPeerId: string;

    newPeer.on("open", (id) => {
      doctorPeerId = id;
      sessionService.updateSessionInformation(sessionId!, {
        offerer: id!,
      });
    });

    setOffererPeerId(doctorPeerId!);

    setPeer(newPeer);

    const realtimeConnection = sessionService.connectToSessionRealtime(
      sessionId as string
    );

    sessionService.realtimeConnection = realtimeConnection;
    sessionService.realtimeConnectionSubscription =
      realtimeConnection.subscribe((data) => {
        setReceiverPeerId(data.receiver!);

        const doctorInformations: PeerInformations = {
          currentId: doctorPeerId!,
          previousId: data.offerer!,
        };

        const patientInformations: PeerInformations = {
          currentId: data.receiver!,
          previousId: data.previous_receiver_id!,
        };

        const doctorPeerAvailability = new DoctorPeerAvailabilityHandler();
        const patientPeerAvailability = new PatientPeerAvailabilityHandler();

        doctorPeerAvailability.setNext(patientPeerAvailability);

        const request = { doctorInformations, patientInformations };

        const result = doctorPeerAvailability.handle(request);
        console.log("connected", result);
        if (!result.success) {
          setIsConnectionAvailable(false);
          setDisconnectedPeer(
            result.stoppedBy === "DoctorPeerAvailabilityHandler"
              ? "doctor"
              : "patient"
          );
          setTimeout(() => {
            setIsSessionStartModalOpen(true);
            setIsConnectionAvailable(true);
          }, 2000);
        }
      });
    setInterval(checkSessionDuration, 1000);
    return () => {
      newPeer.destroy();
      sessionService.realtimeConnectionSubscription.unsubscribe();
    };
  }, []);


  useEffect(() => {
    initializeSessionVideo();
  }, []);

  const initializeSessionVideo = async () => {
    const session = await sessionService.getSession(sessionId!);
    handleSession(session);
  };

  useEffect(() => {
    if (!videoPlayer) return;

    const handleTimeUpdate = () => {
      const currentTime = videoPlayer.currentTime();
      if (currentTime >= 2) {
        videoPlayer.pause();
        videoPlayer.currentTime(0);
        setIsVideoPaused(true);
        videoPlayer.off("timeupdate", handleTimeUpdate);
      }
    };

    videoPlayer.on("timeupdate", handleTimeUpdate);

    return () => {
      videoPlayer.off("timeupdate", handleTimeUpdate);
    };
  }, [videoPlayer]);

  useEffect(() => {
    if (!connection) return;

    videoPlayer.on("loadeddata", () => {
      const buffer = getBufferedAmount(videoPlayer);
      setDoctorBufferedAmount(buffer);
    });

    videoPlayer.on("play", () => {
      playOrPause("play", connection);
    });
    videoPlayer.on("pause", () => {
      playOrPause("pause", connection);
    });
    videoPlayer.on("seeked", () => {
      seekVideo(connection);
    });
  }, [connection]);

  const handleVideos = async (session: Sessions) => {
    if (!session?.videos?.length) return;

    const currentVideoIdOfSession =
      session.current_video || session.videos[session.videos.length - 1];
    localStorage.setItem("currentVideo", currentVideoIdOfSession);
    const shouldLoadVideo =
      !sessionService.sessionVideos?.length ||
      !PlayerService.currentSrcId ||
      PlayerService.currentSrcId != currentVideoIdOfSession;

    if (shouldLoadVideo) {
      const fetchedVideos = await sessionService.getSessionVideos(
        session.videos
      );
      sessionService.sessionVideos = fetchedVideos;
      const video = session.current_video
        ? fetchedVideos.find((vid) => vid._id == session.current_video)!
        : fetchedVideos[fetchedVideos.length - 1];

      setTitle(video?.title!);
      // setLoading(false);
      await initializeVideo(video);
    }
  };

  const handleSession = async (session: Sessions) => {
    sessionService.session = session;

    await handleVideos(session);

    const isSessionStarted = session.started_at != null;
    const isPatientJoined = session.patient_joined_at != null;

    if (!isSessionStarted && isPatientJoined) {
      sessionService.patch({
        started_at: new Date(),
      });
    }
  };

  const saveOffererPeerId = useCallback(async () => {
    sessionService.updateSessionInformation(sessionId!, {
      offerer: offererPeerId!,
    });
  }, [offererPeerId]);

  const connectToPatientPeer = async (
    peer: Peer,
    patientPeerId: string,
    offererPeerId: string,
    doctorBuffer: number
  ) => {
    console.log("offerer", offererPeerId);

    sessionService.updateSessionInformation(sessionId!, {
      offerer: offererPeerId!,
      offerer_latest_id: offererPeerId,
      previous_receiver_id: patientPeerId,
    });
    const connectionOffer = peer.connect(patientPeerId!);
    if (!connectionOffer) return;
    setConnection(connectionOffer);
    connectionOffer.on("open", async () => {
      console.log("connection on Doctor Screen", connectionOffer);
      connectionOffer.send({
        message: "CONNECTED",
        bufferedAmount: doctorBuffer,
      });
      connectionOffer.on("data", (data: any) => {
        if (data.videoStatus) {
          console.log("doctor received data", data);
          setPatientVideoStatus(data.videoStatus);
        }

        if (data.patientFocusedPoints) {
          updateDoctorView(data.patientFocusedPoints);
        }
      });
    });
  };

  const updateDoctorView = (points: Sessions["patient_focused_points"]) => {
    let cloneVector = PlayerService.player.vr().camera?.position.clone();
    if (!cloneVector) return;

    cloneVector.x = points?.x || 0;
    cloneVector.y = points?.y || 0;
    cloneVector.z = points?.z || 0;

    TweenService.animateVector3(
      PlayerService.player.vr().camera?.position,
      cloneVector,
      300
    );
  };

  const getBufferedAmount = (player: any) => {
    const buffered = player.buffered();
    const currentTime = player.currentTime();
    let bufferedAmount = 0;

    for (let i = 0; i < buffered.length; i++) {
      const start = buffered.start(i);
      const end = buffered.end(i);

      if (currentTime >= start && currentTime <= end) {
        bufferedAmount = end - currentTime;
        break;
      }
    }

    return bufferedAmount;
  };

  const initializeVideo = async (video: Video_Metadata) => {
    const isVideoElementMounted = videoRef.current != null;
    console.log("video content", video.content);

    if (!isVideoElementMounted || !video) return;

    let configs = {
      controls: true,
      autoplay: false,
      fluid: true,
      loop: false,
      preload: "auto",
      buffered: [0.0, 40.0],
      muted: true,
      sources: [{ src: video.content!, type: "video/mp4" }],
      plugins: {
        vr: {
          polyfill: true,
          projection: "360",
          debug: false,
          forceCardboard: false,
        },
      },
    };

    const devices = ["Windows Phone", "Android", "iOS", "macOS", "Windows"];
    const device = sessionService.getMobileOperatingSystem();
    if (!devices.includes(device!)) {
      configs = {
        ...configs,
        plugins: {
          ...configs.plugins,
          vr: {
            ...configs.plugins.vr,
            projection: "AUTO",
          },
        },
      };
    }

    let player: any = videojs(videoRef.current!, configs);

    setVideoPlayer(player);

    PlayerService.player = player;
    PlayerService.currentSrcId = video._id as string;
    return () => {
      player.dispose();
    };
  };

  const playOrPause = (action: string, connection: DataConnection) => {
    const time = PlayerService.player.currentTime();
    connection.send({ action, time });
  };

  const seekVideo = (connection: DataConnection) => {
    PlayerService.player.pause();
    const seekedSecond = PlayerService.player.currentTime();
    connection.send({ seekedSecond });
  };

  const copySessionUrlToClipboard = () => {
    navigator.clipboard.writeText(sessionUrl);
    setClipboardTooltip("Kopyalandı");
    setTimeout(
      () => setClipboardTooltip("Seans bağlantı linkini kopyala"),
      1500
    );
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const checkSessionDuration = () => {
    const { configs, session, isSessionEndSoonPopupShowed } = sessionService;

    if (isSessionEndSoonPopupShowed || !configs?.length || session?.end_at)
      return;

    const diffFromSessionStart =
      new Date().getTime() - new Date(session?.started_at as string).getTime();
    const remainingTime =
      ((session?.extend_count || 0) + 1) *
        (getConfigValueByKey("session_duration_as_ms", true) as number) -
      diffFromSessionStart;
    const isSessionEnded = !session?.end_at && remainingTime < 0;

    if (isSessionEnded) {
      setIsSessionEndSoonPopupVisible(false);
      return finishSession();
    }

    const shouldShowWarningPopup =
      (getConfigValueByKey(
        "doctor_warning_time_for_session_in_ms",
        true
      ) as number) > remainingTime;
    if (shouldShowWarningPopup && !isSessionEndSoonPopupShowed) {
      setIsSessionEndSoonPopupVisible(true);
      sessionService.isSessionEndSoonPopupShowed = true;
    }
  };

  const getConfigValueByKey = (key: string, convertToNumber?: boolean) => {
    const value = sessionService.configs?.find(
      (config) => config.key == key
    )?.value_as_string;
    return !convertToNumber ? value : Number(value);
  };

  const finishSession = () => {
    const diffFromSessionStart =
      new Date().getTime() -
      new Date(sessionService.session?.started_at as string).getTime();
    const shouldDecreaseRemainingSession =
      diffFromSessionStart >
      (getConfigValueByKey("min_session_duration_in_ms", true) as number);

    if (shouldDecreaseRemainingSession) {
      decreaseUserRemainingSession();
    }

    let viewingHistory;

    const viewHistoryOfVideo = getViewHistoryOfVideo();

    viewingHistory = session?.viewing_history || [];
    viewingHistory.push(viewHistoryOfVideo);

    sessionService.patch({
      end_at: new Date(),
      viewing_history: viewingHistory,
      status: false,
    });
    navigateToHome();
    localStorage.removeItem("");
  };

  const decreaseUserRemainingSession = () => {
    return userSerivce.updateUser(user._id as string, {
      remaining_sessions: (user.remaining_sessions as number) - 1,
    });
  };

  const getViewHistoryOfVideo = () => {
    const session = sessionService.session;
    const diff =
      new Date().getTime() -
      new Date(
        (session?.video_changed_at || session?.started_at) as string
      ).getTime();
    const video = PlayerService.currentSrcId!;
    return {
      duration_in_ms: diff,
      video,
    };
  };

  const navigateToHome = () => {
    navigate("/");
    sessionService.patch({ status: false });
  };

  const extendSession = () => {
    setIsSessionEndSoonPopupVisible(false);

    if (user.remaining_sessions) {
      decreaseUserRemainingSession();
      const extend_count = (sessionService.session?.extend_count as number) + 1;
      sessionService.patch({
        extend_count: extend_count,
      });
    } else {
      setIsSessionCountFinishedPopupVisible(true);
    }
  };

  const dialogs: IWGlobalDialog["dialogs"] = [
    {
      title: "Seans uzatma hakkınız kalmadı",
      buttons: [
        {
          children: "Tamam",
          onClick: () => setIsSessionCountFinishedPopupVisible(false),
        },
        {
          children: "Satın Al",
          onClick: () => navigate("/subscription"),
        },
      ],
      isDialogVisible: isSessionCountFinishedPopupVisible,
      onClose: () => setIsSessionCountFinishedPopupVisible(false),
    },
    {
      title: "Seansınız yakında bitecektir",
      buttons: [
        {
          children: "Tamam",
          onClick: () => setIsSessionEndSoonPopupVisible(false),
        },
        {
          children: "Seansı Uzat",
          onClick: extendSession,
        },
      ],
      isDialogVisible: isSessionEndSoonPopupVisible,
      onClose: () => setIsSessionEndSoonPopupVisible(false),
    },
    {
      title: "Bu seans gerçekleştirilmiştir.",
      buttons: [
        {
          children: "Ana sayfa",
          onClick: navigateToHome,
        },
      ],
      isDialogVisible: isSessionFinishedDialogVisible,
      onClose: navigateToHome,
    },
    {
      title: "Seansı Sonlandır",
      description: "Seans sonlandırılıyor.",
      buttons: [
        {
          children: "Onayla",
          onClick: () => {
            hideFinishSessionDialog();
            finishSession();
          },
        },
        {
          children: "İptal Et",
          onClick: () => {
            hideFinishSessionDialog();
          },
        },
      ],
      isDialogVisible: isFinishSessionDialogVisible,
      onClose: hideFinishSessionDialog,
    },
    {
      title: "Baglantı oluşturuluyor",
      description: "Bağlantı bilgilerı hazırlanana kadar bekleyin",
      buttons: [],
      isDialogVisible: !isConnectionAvailable,
      onClose: () => {},
    },
    {
      title: `Bağlantı bilgileri hazır`,
      description: "",
      buttons: [
        {
          children: "Devam",
          onClick: async () => {
            // await saveOffererPeerId();
            setIsSessionStartModalOpen(false);
          },
        },
      ],
      isDialogVisible: isSessionStartModalOpen,
      onClose: () => {
        setIsSessionStartModalOpen(false);
      },
    },

    {
      title: "Hasta hazır",
      description: "",
      buttons: [
        {
          children: "Bağlantıyı Kur",
          onClick: () => {
            connectToPatientPeer(
              peer!,
              receiverPeerId!,
              offererPeerId!,
              doctorBufferedAmount!
            );
            setIsSessionStartModalOpen(false);
          },
        },
      ],
      isDialogVisible:
        disconnectedPeer === "patient" && isSessionStartModalOpen,
      onClose: () => {
        connectToPatientPeer(
          peer!,
          receiverPeerId!,
          offererPeerId!,
          doctorBufferedAmount!
        );
        setIsSessionStartModalOpen(false);
      },
    },
  ];

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpened(false);
  };

  const handleVideoChangeClick = () => {
    handleMenuClose();
    setIsVideoLibraryVisible(true);
  };

  const changeSessionVideo = (
    connection: DataConnection,
    video: Video_Metadata
  ) => {
    setIsVideoLibraryVisible(false);
    const isSameVideo = PlayerService.currentSrcId == video._id;
    if (isSameVideo) return;

    const videos = sessionService.session?.videos;
    const indexOfVideoId = videos?.indexOf(video._id as string);

    if (indexOfVideoId !== -1) {
      videos?.splice(indexOfVideoId!, 1);
    }

    videos?.push(video._id as string);

    const patchObject: Sessions = {
      videos: videos,
      video_changed_at: new Date(),
      current_video: video._id,
      video_state: {
        is_playing: false,
        user_seconds: 0,
      },
    };

    if (!session?.started_at) {
      const viewHistoryOfVideo = getViewHistoryOfVideo();
      const viewingHistory = session?.viewing_history || [];

      viewingHistory.push(viewHistoryOfVideo);
      patchObject["viewing_history"] = viewingHistory;
    }

    sessionService.patch(patchObject);

    if (PlayerService.player) {
      PlayerService.player.src({
        src: video.content!,
        type: "video/mp4",
      });
    }

    connection?.send({
      src: video.content!,
      type: "video/mp4",
    });

    PlayerService.currentSrcId = video._id!;
  };

  return (
    <div className={styles["container"]}>
      <WMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpened}
        onClose={handleMenuClose}
      >
        <WMenuItem onClick={handleVideoChangeClick}>Video Değiştir</WMenuItem>
      </WMenu>
      <SessionTopBar
        rightItemsVisibility={true}
        title={title as string}
        rightItems={topBarRightItems}
        onBackClick={showFinishSessionDialog}
        backIcon={<ArrowBackRoundedIcon />}
      />
      <div className={styles["videoContainer"]}>
        {patientVideoStatus && (
          <>
            {patientVideoStatus === "waiting" ? (
              <div
                className={styles["patientInformationContainer"]}
                key={patientVideoStatus}
              >
                <p>Hastanın internet bağlantısı kontrol ediliyor...</p>
              </div>
            ) : null}
          </>
        )}
        {!isVideoPaused && (
          <div className={styles["overlay"]}>
            Video yüklenirken lütfen bekleyiniz...
          </div>
        )}

        <div data-vjs-player>
          <video
            muted={true}
            ref={videoRef}
            preload="auto"
            autoPlay
            className={`video-js vjs-default-skin vjs-v7 ${styles["video"]}`}
            crossOrigin="anonymous"
            controls={true}
            playsInline={true}
            id="vrvideo"
          ></video>
        </div>
        {<WGlobalDialog dialogs={dialogs} />}
        <WDialog
          open={isVideoLibraryVisible}
          onClose={() => setIsVideoLibraryVisible(false)}
          fullWidth={true}
          maxWidth={false}
        >
          <Library
            onVideoClick={(video: Video_Metadata) =>
              changeSessionVideo(connection!, video)
            }
          />
        </WDialog>
      </div>
    </div>
  );
};

export default DoctorScreen;
