import Peer, { DataConnection } from "peerjs";
import React, { useCallback, useEffect, useRef, useState } from "react";
import sessionService from "../../services/session.service";
import { useParams } from "react-router-dom";
import styles from "./PatientScreen.module.scss";
import VideoPlayer from "../video-player/VideoPlayer";
import WGlobalDialog, {
  IWGlobalDialog,
} from "../../prefabs/global-dialog/global-dialog";
import { useDispatch } from "react-redux";
import { setVideoPlayer } from "../../redux/video-player/videoplayer.slice";

import videojs from "video.js";
import "videojs-vr";
import "../../../node_modules/video.js/dist/video-js.css";
import "../../../node_modules/videojs-vr/dist/videojs-vr.css";
import { Sessions, Video_Metadata } from "../../services/spica/bucket/bucket";
import PlayerService from "../../services/player.service";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { v4 as uuidv4 } from "uuid";

const PatientScreen = () => {
  const { sessionId } = useParams();

  const [peer, setPeer] = useState<Peer | null>(null);
  const [video, setVideo] = useState<any>(null);

  const [connection, setConnection] = useState<DataConnection | null>(null);

  const [isConnected, setIsConnected] = useState<boolean>(false);

  const [bufferedAmount, setBufferedAmount] = useState<number>(0);
  const [isVideoPaused, setIsVideoPaused] = useState<boolean>(false);
  // const [device, setDevice] = useState<string | null>(null);

  const [isSessionStartModalOpen, setIsSessionStartModalOpen] = useState(true);

  const dispatch = useDispatch();

  const [isSessionFinishedDialogVisible, setIsSessionFinishedDialogVisible] =
    useState(false);

  const [isPatientKickedDialogVisible, setIsPatientKickedDialogVisible] =
    useState(false);

  const [patientFocusedPointsInterval, setPatientFocusedPointsInterval] =
    useState<NodeJS.Timer>();

  const videoRef = useRef<HTMLVideoElement>(null);

  const getPermission = async () => {
    try {
      //@ts-ignore
      const permission = await DeviceMotionEvent.requestPermission();
      if (permission !== "granted") {
        console.warn("Permission to access device motion was denied.");
      }
    } catch (error) {
      console.error("Error requesting device motion permission:", error);
    }
  };

  const checkNeedPermission = async () => {
    const device = sessionService.getMobileOperatingSystem();

    if (device === "iOS") {
      await getPermission();
    }
  };

  const letPatientJoindToSession = () => {
    checkNeedPermission();
    sessionService.patch({
      patient_joined_at: new Date(),
    });
    setIsSessionStartModalOpen(false);
  };

  const sendBufferToDoctor = (
    connection: DataConnection,
    bufferAmount: number
  ) => {
    connection.send({ bufferAmount });
  };

  const dialogs: IWGlobalDialog["dialogs"] = [
    {
      title: "Bu seans gerçekleştirilmiştir.",
      buttons: [],
      isDialogVisible: isSessionFinishedDialogVisible,
      onClose: () => {},
    },
    {
      title:
        "Seansa başka bir cihazdan giriş yapıldığı için oturumunuz sonlanmıştır.",
      buttons: [],
      isDialogVisible: isPatientKickedDialogVisible,
      onClose: () => {},
    },
    {
      title: "Seans Hazır",
      description: "Seansa Katılabilirsiniz",
      buttons: [
        {
          children: "Katıl",
          onClick: () => {
            letPatientJoindToSession();
          },
        },
      ],
      isDialogVisible: isVideoPaused && isSessionStartModalOpen,
      onClose: () => {
        letPatientJoindToSession();
      },
    },
  ];

  const getBufferedAmount = (player: any) => {
    const buffered = player.buffered();
    const currentTime = player.currentTime();
    let bufferedAmount = 0;

    for (let i = 0; i < buffered.length; i++) {
      const start = buffered.start(i);
      const end = buffered.end(i);

      if (currentTime >= start && currentTime <= end) {
        bufferedAmount = end - currentTime;
        break;
      }
    }

    return bufferedAmount;
  };

  const initializeVideo = async (video: Video_Metadata) => {
    const isVideoElementMounted = videoRef.current != null;
    if (!isVideoElementMounted || !video) return;

    let configs = {
      controls: true,
      autoplay: true,
      fluid: true,
      loop: false,
      preload: "auto",
      buffered: [0.0, 40.0],
      muted: true,
      sources: [{ src: video.content!, type: "video/mp4" }],
      plugins: {
        vr: {
          polyfill: true,
          projection: "360",
          debug: false,
          forceCardboard: false,
        },
      },
    };

    const devices = ["Windows Phone", "Android", "iOS", "macOS", "Windows"];
    const device = sessionService.getMobileOperatingSystem();
    if (!devices.includes(device!)) {
      configs = {
        ...configs,
        plugins: {
          ...configs.plugins,
          vr: {
            ...configs.plugins.vr,
            projection: "AUTO",
          },
        },
      };
    }

    let player: any = videojs(videoRef.current!, configs);
    setVideo(player);

    player.on("pause", () => {
      const buffer = getBufferedAmount(player);
      setBufferedAmount(buffer);
    });

    PlayerService.player = player;
    PlayerService.currentSrcId = video._id as string;

    return () => {
      player.dispose();
    };
  };

  const patchPatientFocusedPoints = useCallback(
    (conn: DataConnection) => {
      if (!PlayerService.player || !PlayerService.player.vr().camera) return;

      const { x, y, z } = PlayerService.player.vr().camera.position;

      conn?.send({
        patientFocusedPoints: { x, y, z },
      });
    },
    [connection]
  );

  useEffect(() => {
    if (!video) return;

    const handleTimeUpdate = () => {
      const currentTime = video.currentTime();
      if (currentTime >= 8) {
        video.pause();
        video.currentTime(0);
        setIsVideoPaused(true);
        video.off("timeupdate", handleTimeUpdate);
      }
    };

    video.on("timeupdate", handleTimeUpdate);

    return () => {
      video.off("timeupdate", handleTimeUpdate);
    };
  }, [video]);

  useEffect(() => {
    initializeSessionVideo();
  }, []);

  useEffect(() => {
    sessionService.fetchConfigs();

    registerDevice();
    const realtimeConnection = sessionService.connectToSessionRealtime(
      sessionId as string
    );
    sessionService.realtimeConnection = realtimeConnection;
    sessionService.realtimeConnectionSubscription =
      realtimeConnection.subscribe((data) => {
        if (localStorage.getItem("registration_id")) {
          shouldKickPatient(data);
        }
      });

    return () => {
      sessionService.realtimeConnectionSubscription.unsubscribe();
    };
  }, []);

  const initializeSessionVideo = async () => {
    const session = await sessionService.getSession(sessionId!);
    handleVideos(session);
  };

  const registerDevice = () => {
    const isDeviceRegistered = localStorage.getItem("registration_id");
    if (isDeviceRegistered) return;

    const uuid = uuidv4();
    localStorage.setItem("registration_id", uuid);
  };

  const handleVideos = async (session: Sessions) => {
    if (!session?.videos?.length) return;

    const currentVideoIdOfSession =
      session.current_video || session.videos[session.videos.length - 1];
    localStorage.setItem("currentVideo", currentVideoIdOfSession);
    const shouldLoadVideo =
      !sessionService.sessionVideos?.length ||
      !PlayerService.currentSrcId ||
      PlayerService.currentSrcId != currentVideoIdOfSession;

    if (shouldLoadVideo) {
      const fetchedVideos = await sessionService.getSessionVideos(
        session.videos
      );
      sessionService.sessionVideos = fetchedVideos;
      const video = session.current_video
        ? fetchedVideos.find((vid) => vid._id == session.current_video)!
        : fetchedVideos[fetchedVideos.length - 1];

      await initializeVideo(video);
    }
  };

  const shouldKickPatient = (session: Sessions) => {
    const registrationId = localStorage.getItem("registration_id");
    const shouldUpdatePatientRegistrationId =
      registrationId && !sessionService.isPatientRegistrationIdUpdated;

    const isSessionEnded = session.end_at != null;

    if (isSessionEnded) {
      setIsSessionFinishedDialogVisible(true);
      return;
    }

    if (shouldUpdatePatientRegistrationId) {
      sessionService.isPatientRegistrationIdUpdated = true;
      session.patient_registration_id = registrationId;
      sessionService.patch({
        patient_registration_id: registrationId,
      });
    }

    const shouldKickPatient = session.patient_registration_id != registrationId;

    if (shouldKickPatient) {
      kickPatientFromSession();
    }
  };

  const kickPatientFromSession = () => {
    setIsPatientKickedDialogVisible(true);
    sessionService.realtimeConnectionSubscription.unsubscribe();
    clearInterval(patientFocusedPointsInterval);
  };

  useEffect(() => {
    if (!video || !connection) return;
    const readyLevel = video.readyState();
    const netWork = video.networkState();
    video.on("timeupdate", () => {
      if (readyLevel >= 3) {
        connection.send({ videoStatus: "ready" });
      } else {
        console.log("readyLevel in else", readyLevel);

        connection.send({ videoStatus: "waiting" });
      }
    });
  }, [video, connection]);

  const playOrPause = (action: string, time: number) => {
    PlayerService.player.currentTime((time = 0 ? 2 : time));
    if (action === "play") {
      PlayerService.player.play();
    } else {
      PlayerService.player.pause();
    }
  };

  const seekVideo = (connection: DataConnection, time: number) => {
    console.log("her is working");

    connection.send({ videoStatus: "waiting" });
    PlayerService.player.currentTime(time);
  };

  const changeVideo = (video: any) => {
    if (PlayerService.player) {
      PlayerService.player.src({
        src: video.src!,
        type: "video/mp4",
      });
    }
  };

  useEffect(() => {
    // setDevice(sessionService.getMobileOperatingSystem());
    const newPeer = new Peer();

    newPeer.on("open", async (id) => {
      const previousReceiverId = (await sessionService.getSession(sessionId!))
        .receiver;
      sessionService.updateSessionInformation(sessionId!, {
        previous_receiver_id: previousReceiverId || "",
        receiver: id,
      });
    });
    setPeer(newPeer);

    newPeer.on("connection", (connection: DataConnection) => {
      setConnection(connection);
      connection.on("open", () => {
        connection.on("data", (data: any) => {
          console.log("received data in patient", data);
          if (data.message == "CONNECTED") {
            setIsConnected(true);
          }
          if (data.action) {
            playOrPause(data.action, data.time);
          }
          if (data.seekedSecond && data.seekedSecond > 0) {
            seekVideo(connection, data.seekedSecond);
          }
          if (data.src) {
            changeVideo(data);
          }
        });
      });

      if (patientFocusedPointsInterval) return;
      setPatientFocusedPointsInterval(
        setInterval(() => {
          patchPatientFocusedPoints(connection);
        }, 100)
      );
    });
    return () => {
      clearInterval(patientFocusedPointsInterval);
      newPeer.destroy();
    };
  }, []);

  return (
    <div className={styles["container"]}>
      <div className={styles["informUser"]}>
        <p>Videoya ulaşmak için aşağı kaydırın</p>
        <KeyboardDoubleArrowDownIcon className={styles["arrow"]} />
      </div>
      <div className={styles["videoPlayer"]}>
        {!isVideoPaused && (
          <div className={styles["overlay"]}>
            <div> Video yüklenirken lütfen bekleyiniz....</div>
          </div>
        )}

        <div data-vjs-player>
          <video
            muted={true}
            ref={videoRef}
            preload="auto"
            autoPlay
            className={`video-js vjs-default-skin vjs-v7 ${styles["video"]}`}
            crossOrigin="anonymous"
            controls={true}
            playsInline={true}
            id="vrvideo"
          ></video>
        </div>
        {<WGlobalDialog dialogs={dialogs} />}
      </div>
    </div>
  );
};

export default PatientScreen;
