import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { iUser } from "../../interfaces/common";

interface iVideoPlayer {
    videoPlayer: {};
}

const initialState: iVideoPlayer = {
    videoPlayer: {},
};

export const videoPlayerSlice = createSlice({
    name: "videoPlayer",
    initialState,
    reducers: {
        setVideoPlayer(state, action: PayloadAction<any>) {
            return { ...state, videoPlayer: action.payload };
        },
    },
});

export const { setVideoPlayer } = videoPlayerSlice.actions
export const videoPlayerReducer = videoPlayerSlice.reducer;